import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

const stringifyError = (error, levels = 5) => {
    const errorObj = {};
    const props = Object.getOwnPropertyNames(error);
    for (let i = 0; i < props.length; i++) {
        const key = props[i];
        if (key === 'stack') {
            errorObj[key] = error[key].split('\n').slice(0, levels).join('\n');
        } else {
            errorObj[key] = error[key];
        }
    }
    const out = JSON.stringify(errorObj, null, 2);
    return out;
};

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export { stringifyError };
