
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import HomePage from '../components/HomePage';
import Head from 'next/head';
import { useState, useEffect } from 'react';
import { cachedDataWrapper } from '../lib/dataFetcher';
async function getStaticProps() {
    try {
        const data = await cachedDataWrapper();
        return {
            props: {
                initialData: data
            },
            revalidate: 60 // This allows incremental static regeneration
        };
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return {
            props: {
                initialData: {
                    error: "Something went wrong. I'm probably working on it!"
                }
            },
            revalidate: 60 // Also for incremental static regeneration
        };
    }
}
function Home({ initialData }) {
    const [fetchedData, setFetchedData] = useState(initialData);
    // Fetch data on the client side
    const fetchData = async () => {
        try {
            const response = await fetch('/api/fetchData'); // Assuming you have an API route for fetching data
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();
            setFetchedData(data);
        }
        catch (e) {
            // Handle error
            setFetchedData({
                error: "Something went wrong. I'm probably already working on it!"
            });
        }
    };
    useEffect(() => {
        const fetchDataAndSetInterval = () => {
            // fetchData(); // Initial data fetch - not needed because of ISR
            const intervalId = setInterval(() => {
                fetchData();
            }, 1000 * 60);
            // Cleanup the interval when the component unmounts
            return () => {
                clearInterval(intervalId);
            };
        };
        fetchDataAndSetInterval(); // Call the function to set up the interval
        // Specify an empty dependency array to ensure this effect runs only once
    }, []);
    return (<>
            <Head>
                <title>CPH SECURITY WAIT TIME</title>
                <meta property="og:title" content="CPH SECURITY WAIT TIME - CPH SIKKERHEDSKONTROL VENTETID"/>
                <meta name="robots" content="all"/>
                <meta name="description" content="Get the current security wait time at CPH airport and also see the next delayed flights!"/>
                <meta property="og:description" content="Get the current security wait time at CPH airport and also see the next delayed flights!"/>
                <meta name="keywords" content="cph, airport, security wait time, kastrup, waiting time, security waiting time, fly, airplane, ventetid, sikkerhedskontrol, sas, lufthavn, delays, forsinkelser"/>
                <meta charSet="utf-8"/>
                <meta name="author" content="Morten Laursen"/>
                <meta name="copyright" content="Morten Laursen"/>
                <link rel="icon" href="/favicon.ico"/>
            </Head>
            <HomePage waitTime={fetchedData?.waitTime} lastUpdated={fetchedData?.lastUpdated} metaTags={fetchedData?.metaTags} error={fetchedData?.error} delayedFlights={fetchedData?.delayedFlights} summary={fetchedData?.summary} lastUpdatedUpdated={fetchedData?.lastUpdatedUpdated} timezone={fetchedData?.timezone}/>
        </>);
}
export default Home;

    async function __Next_Translate__getStaticProps__193abaef516__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193abaef516__ as getStaticProps }
  