import { cn } from '../lib/utils';

const Pulse = ({ className }) => {
    return (
        <div
            className={cn(
                'inline-block rounded-full bg-current animate-pulse align-middle m-auto my-5 p-1 w-1 h-1',
                className
            )}
        ></div>
    );
};

export default Pulse;
