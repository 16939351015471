import React, { useEffect, useState } from 'react';

const FormattedDate = ({ date, locale, timeZone }) => {
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
        if (date) {
            setFormattedDate(
                new Date(date).toLocaleString(locale, {
                    timeZone: timeZone
                })
            );
        }
    }, [date, locale, timeZone]);

    return <>{formattedDate}</>;
};

export default FormattedDate;
